// Component-specific overlay pane styles

@use '../../density-styles-sass' as *;

.cdk-overlay-pane {
  &--#{$prefix}toast-queue {
    margin: calc(var(--#{$prefix}shape-size-y-header) + var(--#{$prefix}space-stack-toast-area))
      var(--#{$prefix}space-inline-toast-area) var(--#{$prefix}space-stack-toast-area);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &--#{$prefix}loading-area {
    // Using !important to overwrite the inline style property that is applied automatically by CDK Overlay
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
  }
}

.#{$prefix}loading-area-overlay-container .cdk-overlay-container.in-container {
  position: absolute;

  .cdk-overlay-connected-position-bounding-box--#{$prefix}loading-area {
    // Using !important to overwrite the inline style property that is applied automatically by CDK Overlay
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}
