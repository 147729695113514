@use 'sass:selector';

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector.append(&, $sel))} {
    @content;
  }
}
@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
