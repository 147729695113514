// Temporarily adds a watermark to the page to indicate that it is a draft
body::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('/assets/draft.png');
  opacity: 0.1;
  pointer-events: none;
}
