@use '../ag-grid-theme-icon-variables' as icons;

$-theme-params: ();

@function get-theme-params() {
  @return $-theme-params;
}

@mixin render-extras($prefix: ds-) {
  .ag-theme-density {
    --ag-icon-font-code-menu-alt: '\e907';

    .ag-panel-title-bar.ag-default-panel-title-bar {
      background-color: var(--ag-control-panel-background-color);
    }

    &:not(.zebra) {
      .ag-sticky-top {
        background-color: var(--#{$prefix}color-surface-page);

        .ag-row.ag-row-group {
          background-color: var(--#{$prefix}color-surface-page);
        }
      }

      .ag-center-cols-viewport {
        .ag-row-hover.ag-row-footer:not(:hover),
        .ag-row-hover.ag-row-group-expanded:not(:hover) {
          &.ag-row-odd::before {
            background-color: var(--ag-odd-row-background-color);
            background: var(--ag-odd-row-background-color);
          }

          &.ag-row-even::before {
            background-color: var(--ag-odd-row-background-color);
            background: var(--ag-odd-row-background-color);
          }
        }
      }
    }

    .ag-center-cols-viewport {
      .ag-row-hover.ag-row-footer:not(:hover),
      .ag-row-hover.ag-row-group-expanded:not(:hover) {
        &.ag-row-odd::before {
          background-color: var(--ag-odd-row-background-color);
          background: var(--ag-odd-row-background-color);
        }

        &.ag-row-even::before {
          background-color: var(--#{$prefix}color-surface-base);
          background: var(--#{$prefix}color-surface-base);
        }
      }
    }

    .ag-rich-select-row-selected.ag-rich-select-row-highlighted:not(:hover) {
      background-image: none;
    }
  }
}
