/*! Global styles for Angular applications */

/*
 * Library CSS variables. Must have.
 */
@forward './variables/variables';

/*
 * Basic set of styles for Density that unifies default styling accross different implementations of browser vendors. Based on Bootstrap Reboot and Sanitize.css. Recommended.
 */
@forward './reboot/reboot';

/*
 * Fonts
 */
@forward './fonts/fonts';

/*
 * Utilities classes like spacing, basic typography rules, alignments. Recommended.
 */
@forward './utilities/utilities';

/*
 * Grid, containers and media breakpoints system.
 */
@forward './grid/grid';

/*
 * Styling of native elements
 */
@forward './elements/elements';

/*
 * Animations
 */
@forward './animations/animations';

/*
 * Declarations of functions used in the library. Must have.
 */
@forward './functions/functions';
