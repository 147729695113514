@use '../../density-styles-sass' as *;

/* Selects a shadow root host only if it is matched by the selector argument.
 * We add this argument to avoid conflicts when library is used independently with Shadow DOM.
 */
:host(#{$prefix}table),
:host(.#{$prefix}table),



/* CSS selector for independent library use without Shadow DOM */
.#{$prefix}table {
  // CSS variables used by sort-header to take into account the dividers when placing the focus ring.
  // The variables are set when the corresponding divider class is active.
  --table-sort-header-outer-divider-offset: 0px;
  --table-sort-header-column-divider-offset: 0px;
  --table-sort-header-row-divider-offset: 0px;

  --#{$prefix}font-feature-settings-table: 'tnum';
  --#{$prefix}color-surface-table-header-sticky: var(--#{$prefix}color-surface-page);

  &,
  table {
    position: relative;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-feature-settings: var(--#{$prefix}font-feature-settings-table);

    &.#{$prefix}table--outer-divider {
      --table-sort-header-outer-divider-offset: 1px;

      // table-head outer dividers
      .#{$prefix}table-head th {
        border-top: 1px solid var(--#{$prefix}color-surface-table-dividers);

        &:first-child {
          border-left: 1px solid var(--#{$prefix}color-surface-table-dividers);
          border-top-left-radius: var(--#{$prefix}shape-radius-table);
        }

        &:last-child {
          border-right: 1px solid var(--#{$prefix}color-surface-table-dividers);
          border-top-right-radius: var(--#{$prefix}shape-radius-table);
        }
      }

      // table-row outer dividers
      .#{$prefix}table-row {
        td {
          &:first-child {
            border-left: 1px solid var(--#{$prefix}color-surface-table-dividers);
          }

          &:last-child {
            border-right: 1px solid var(--#{$prefix}color-surface-table-dividers);
          }
        }

        &:last-child td {
          border-bottom: 1px solid var(--#{$prefix}color-surface-table-dividers);

          &:first-child {
            border-left: 1px solid var(--#{$prefix}color-surface-table-dividers);
            border-bottom-left-radius: var(--#{$prefix}shape-radius-table);
          }

          &:last-child {
            border-right: 1px solid var(--#{$prefix}color-surface-table-dividers);
            border-bottom-right-radius: var(--#{$prefix}shape-radius-table);
          }
        }
      }
    }
  }

  &:not(table) {
    display: block;
  }
}
