// Whole or parts of this file is based on Bootstrap. Bootstrap is licensed MIT. https://github.com/twbs/bootstrap

@use '../density-styles-sass' as *;
@use '../mixins/grid' as *;

// Row
//
// Rows contain your columns.

@if $enable-grid-classes {
  .row {
    @include make-row();

    > * {
      @include make-col-ready();
    }
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  // Single container class with breakpoint max-widths
  .container,
  // 100% wide container at all breakpoints
  .container-fluid,
  %container-fluid {
    @include make-container();
  }

  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      // Extend each breakpoint which is smaller or equal to the current breakpoint
      $extend-breakpoint: true;
      %responsive-container-#{$breakpoint} {
        max-width: $container-max-width;
      }

      @each $name, $width in $grid-breakpoints {
        @if ($extend-breakpoint) {
          .container#{breakpoint-infix($name, $grid-breakpoints)} {
            @extend %responsive-container-#{$breakpoint};
          }

          // Once the current breakpoint is reached, stop extending
          @if ($breakpoint == $name) {
            $extend-breakpoint: false;
          }
        }
      }
    }

    .container-#{$breakpoint} {
      @extend %container-fluid;
    }
  }
}
